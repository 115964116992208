<template>
  <ul class="menu-nav">
    <template v-for="item in items">
      <template v-if="item.submenu == null || item.submenu.length === 0">
        <router-link
          :to="'/' + item.page"
          :key="item.key"
          v-slot="{ href, navigate, isActive, isExactActive }"
        >
          <li
            aria-haspopup="true"
            data-menu-toggle="hover"
            class="menu-item"
            :class="[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ]"
          >
            <a :href="href" class="menu-link" @click="navigate">
              <span class="menu-text">
                {{ $t("MENU." + item.code.toUpperCase()) }}
              </span>
            </a>
          </li>
        </router-link>
      </template>
      <template v-else>
        <li
          :key="item.key"
          aria-haspopup="true"
          data-menu-toggle="click"
          class="menu-item menu-item-submenu menu-item-open-dropdown"
          v-bind:class="{ 'menu-item-active': hasActiveChildren(item.page) }"
        >
          <a href="#" class="menu-link menu-toggle">
            <span class="menu-text">
              {{ $t("MENU." + item.code.toUpperCase()) }}
            </span>
          </a>

          <div class="menu-submenu menu-submenu-classic">
            <ul class="menu-subnav">
              <template v-for="menuItem in item.submenu">
                <template v-if="menuItem.submenu">
                  <li
                    :key="menuItem.key"
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item menu-item-submenu menu-item-rel"
                    v-bind:class="{ 'menu-item-active': hasActiveChildren(menuItem.page) }"
                  >
                    <a href="#" class="menu-link menu-toggle">
                      <i :class="'menu-icon ' + menuItem.icon"></i>
                      <span class="menu-text">
                        {{ $t("MENU." + menuItem.code.toUpperCase()) }}
                      </span>
                      <i class="menu-arrow"></i>
                    </a>
                    <div class="menu-submenu menu-submenu-classic menu-submenu-right">
                      <ul class="menu-subnav">
                        <template v-for="subItem in menuItem.submenu">
                          <template v-if="subItem.submenu">
                            <li
                              :key="subItem.key"
                              aria-haspopup="true"
                              data-menu-toggle="hover"
                              class="menu-item menu-item-submenu"
                              :class="{ 
                                'menu-item-active': hasActiveChildren(subItem.page),
                                'menu-item-disabled': subItem.disabled
                              }"
                            >
                              <a href="#" class="menu-link menu-toggle">
                                <i v-if="subItem.bullet === 'dot'" class="menu-bullet menu-bullet-dot">
                                  <span></span>
                                </i>
                                <i v-if="subItem.icon" :class="'menu-icon ' + subItem.icon"></i>
                                <span class="menu-text">
                                  {{ $t("MENU." + subItem.code.toUpperCase()) }}
                                </span>
                                <i class="menu-arrow"></i>
                              </a>
                              <div class="menu-submenu menu-submenu-classic menu-submenu-right">
                                <ul class="menu-subnav">
                                  <router-link
                                    v-for="thirdLevelItem in subItem.submenu"
                                    :key="thirdLevelItem.key"
                                    :to="'/' + thirdLevelItem.page"
                                    v-slot="{ href, navigate, isActive, isExactActive }"
                                  >
                                    <li
                                      aria-haspopup="true"
                                      class="menu-item"
                                      :class="[
                                        isActive && 'menu-item-active',
                                        isExactActive && 'menu-item-active',
                                        thirdLevelItem.disabled && 'menu-item-disabled'
                                      ]"
                                    >
                                      <a :href="href" class="menu-link" @click="thirdLevelItem.disabled ? $event.preventDefault() : navigate">
                                        <i v-if="thirdLevelItem.bullet === 'dot'" :class="'menu-bullet' + (thirdLevelItem.disabled ? 'menu-item-disabled' : '')">
                                          <span></span>
                                        </i>
                                        <i v-if="thirdLevelItem.icon" :class="'menu-icon ' + thirdLevelItem.icon"></i>
                                        <span class="menu-text">
                                          {{ $t("MENU." + thirdLevelItem.code.toUpperCase()) }}
                                        </span>
                                      </a>
                                    </li>
                                  </router-link>
                                </ul>
                              </div>
                            </li>
                          </template>
                          <router-link
                            v-else
                            :key="subItem.key"
                            :to="'/' + subItem.page"
                            v-slot="{ href, navigate, isActive, isExactActive }"
                          >
                            <li
                              aria-haspopup="true"
                              class="menu-item"
                              :class="[
                                isActive && 'menu-item-active',
                                isExactActive && 'menu-item-active',
                                subItem.disabled && 'menu-item-disabled'
                              ]"
                            >
                              <a :href="href" class="menu-link" @click="subItem.disabled ? $event.preventDefault() : navigate">
                                <i v-if="subItem.bullet === 'dot'" :class="'menu-bullet' + (subItem.disabled ? 'menu-item-disabled' : '')">
                                  <span></span>
                                </i>
                                <i v-if="subItem.icon" :class="'menu-icon ' + subItem.icon"></i>
                                <span class="menu-text">
                                  {{ $t("MENU." + subItem.code.toUpperCase()) }}
                                </span>
                              </a>
                            </li>
                          </router-link>
                        </template>
                      </ul>
                    </div>
                  </li>
                </template>
                <router-link
                  v-else
                  :key="menuItem.key"
                  :to="'/' + menuItem.page"
                  v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                    aria-haspopup="true"
                    class="menu-item"
                    :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ]"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <i :class="'menu-icon ' + menuItem.icon"></i>
                      <span class="menu-text">
                        {{ $t("MENU." + menuItem.code.toUpperCase()) }}
                      </span>
                    </a>
                  </li>
                </router-link>
              </template>
            </ul>
          </div>
        </li>
      </template>
    </template>
  </ul>
</template>

<script>
import menuConfig from "@/core/config/menu.config.json";
import Application from "@/application";
import { mapState } from "vuex";

export default {
  name: "KTMenu",
  data() {
    return {
      items: null,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
  created: function () {
    this.checkMenu();
  },
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },

    checkMenu: function () {
      let that = this;
      let allMenuItems = menuConfig.header.items;
      var res = setInterval(function () {
        if (that.user.grantsSet != undefined) {
          that.items = Application.userHasGrantForMenu(that.user, allMenuItems);
          clearInterval(res);
        }
      }, 100);
    },
  },
};
</script>

<style scoped>
.menu-item-disabled {
  opacity: 0.5;
  /* cursor: not-allowed; */
}

.menu-item-disabled a {
  pointer-events: none;
}

.menu-item-disabled .menu-icon {
  color: #B5B5C3 !important;
}

.menu-item-disabled:hover {
  pointer-events: none;
  color: #B5B5C3 !important;
}

.menu-item-disabled .menu-icon {
  color: #B5B5C3 !important;
}
</style>
