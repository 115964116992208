var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"menu-nav"},[_vm._l((_vm.items),function(item){return [(item.submenu == null || item.submenu.length === 0)?[_c('router-link',{key:item.key,attrs:{"to":'/' + item.page},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active',
          ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-text"},[_vm._v(" "+_vm._s(_vm.$t("MENU." + item.code.toUpperCase()))+" ")])])])]}}],null,true)})]:[_c('li',{key:item.key,staticClass:"menu-item menu-item-submenu menu-item-open-dropdown",class:{ 'menu-item-active': _vm.hasActiveChildren(item.page) },attrs:{"aria-haspopup":"true","data-menu-toggle":"click"}},[_c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('span',{staticClass:"menu-text"},[_vm._v(" "+_vm._s(_vm.$t("MENU." + item.code.toUpperCase()))+" ")])]),_c('div',{staticClass:"menu-submenu menu-submenu-classic"},[_c('ul',{staticClass:"menu-subnav"},[_vm._l((item.submenu),function(menuItem){return [(menuItem.submenu)?[_c('li',{key:menuItem.key,staticClass:"menu-item menu-item-submenu menu-item-rel",class:{ 'menu-item-active': _vm.hasActiveChildren(menuItem.page) },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{class:'menu-icon ' + menuItem.icon}),_c('span',{staticClass:"menu-text"},[_vm._v(" "+_vm._s(_vm.$t("MENU." + menuItem.code.toUpperCase()))+" ")]),_c('i',{staticClass:"menu-arrow"})]),_c('div',{staticClass:"menu-submenu menu-submenu-classic menu-submenu-right"},[_c('ul',{staticClass:"menu-subnav"},[_vm._l((menuItem.submenu),function(subItem){return [(subItem.submenu)?[_c('li',{key:subItem.key,staticClass:"menu-item menu-item-submenu",class:{ 
                              'menu-item-active': _vm.hasActiveChildren(subItem.page),
                              'menu-item-disabled': subItem.disabled
                            },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[(subItem.bullet === 'dot')?_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]):_vm._e(),(subItem.icon)?_c('i',{class:'menu-icon ' + subItem.icon}):_vm._e(),_c('span',{staticClass:"menu-text"},[_vm._v(" "+_vm._s(_vm.$t("MENU." + subItem.code.toUpperCase()))+" ")]),_c('i',{staticClass:"menu-arrow"})]),_c('div',{staticClass:"menu-submenu menu-submenu-classic menu-submenu-right"},[_c('ul',{staticClass:"menu-subnav"},_vm._l((subItem.submenu),function(thirdLevelItem){return _c('router-link',{key:thirdLevelItem.key,attrs:{"to":'/' + thirdLevelItem.page},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                                      isActive && 'menu-item-active',
                                      isExactActive && 'menu-item-active',
                                      thirdLevelItem.disabled && 'menu-item-disabled'
                                    ],attrs:{"aria-haspopup":"true"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":function($event){thirdLevelItem.disabled ? $event.preventDefault() : navigate}}},[(thirdLevelItem.bullet === 'dot')?_c('i',{class:'menu-bullet' + (thirdLevelItem.disabled ? 'menu-item-disabled' : '')},[_c('span')]):_vm._e(),(thirdLevelItem.icon)?_c('i',{class:'menu-icon ' + thirdLevelItem.icon}):_vm._e(),_c('span',{staticClass:"menu-text"},[_vm._v(" "+_vm._s(_vm.$t("MENU." + thirdLevelItem.code.toUpperCase()))+" ")])])])]}}],null,true)})}),1)])])]:_c('router-link',{key:subItem.key,attrs:{"to":'/' + subItem.page},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                              isActive && 'menu-item-active',
                              isExactActive && 'menu-item-active',
                              subItem.disabled && 'menu-item-disabled'
                            ],attrs:{"aria-haspopup":"true"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":function($event){subItem.disabled ? $event.preventDefault() : navigate}}},[(subItem.bullet === 'dot')?_c('i',{class:'menu-bullet' + (subItem.disabled ? 'menu-item-disabled' : '')},[_c('span')]):_vm._e(),(subItem.icon)?_c('i',{class:'menu-icon ' + subItem.icon}):_vm._e(),_c('span',{staticClass:"menu-text"},[_vm._v(" "+_vm._s(_vm.$t("MENU." + subItem.code.toUpperCase()))+" ")])])])]}}],null,true)})]})],2)])])]:_c('router-link',{key:menuItem.key,attrs:{"to":'/' + menuItem.page},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active',
                  ],attrs:{"aria-haspopup":"true"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{class:'menu-icon ' + menuItem.icon}),_c('span',{staticClass:"menu-text"},[_vm._v(" "+_vm._s(_vm.$t("MENU." + menuItem.code.toUpperCase()))+" ")])])])]}}],null,true)})]})],2)])])]]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }